<template>
    <div class="container">
        <div class="row">
            <div class="col-12 mb-1 mb-sm-0">
                <div class="mb-2 d-flex align-items-baseline">
                    <span class="me-3">{{ $t('defaultSettingsType') + ':' }}</span>
                    <el-select
                        size="small"
                        :value="defaultSettingsType"
                        @change="handleDefaultSettingsTypeChange"
                        :placeholder="$t('selectType')">
                        <el-option
                            v-for="type in defaultSettingsTypeOptions"
                            :key="type.value"
                            :label="type.label"
                            :value="type.value"/>
                    </el-select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SettingsSettings",
    i18n: {
        messages: {
            en: {
                defaultSettingsType: 'Default settings type',
                selectType: 'Select type',
            },
            ru: {
                defaultSettingsType: 'Тип настроек по умолчанию',
                selectType: 'Выберите тип',
            }
        }
    },
    data: function () {
        return {

        }
    },
    computed: {
        defaultSettingsType: function () {
            return this.$store.getters['admin/symbolSettingsSettings/defaultSettingsType']
        },
        defaultSettingsTypeOptions: function () {
            return this.$store.getters['admin/symbolSettingsSettings/defaultSettingsTypeOptions']
        },
    },
    methods: {
        handleDefaultSettingsTypeChange: function (value) {
            this.$store.commit('admin/symbolSettingsSettings/setDefaultSettingsType', value)
        }
    }
}
</script>

<style scoped>

</style>
