<template>
    <div class="d-flex flex-column wrapper">
        <el-menu
            :default-active="this.$route.fullPath"
            class="d-inline-flex overflow-auto administration-menu"
            style="border-top: 1px solid #e6e6e6"
            mode="horizontal"
            :router="true">
            <AdminSideBarToggle/>
            <el-menu-item index="/admin/administration/accounts">{{ $t('accounts') }}</el-menu-item>
            <el-menu-item index="/admin/administration/charts">{{ $t('charts') }}</el-menu-item>

            <el-submenu index="/admin/administration/settings">
                <template slot="title">{{ $t('settings') }}</template>
                <el-menu-item index="/admin/administration/settings?type=general">General</el-menu-item>
                <el-menu-item index="/admin/administration/settings?type=air">Air</el-menu-item>
                <el-menu-item index="/admin/administration/settings?type=passiveLock">Passive Lock</el-menu-item>
                <el-menu-item index="/admin/administration/settings?type=fishing">Fishing</el-menu-item>
                <el-menu-item index="/admin/administration/settings?type=martin">Martin</el-menu-item>
                <el-menu-item index="/admin/administration/settings?type=signals">Signals</el-menu-item>
                <el-menu-item index="/admin/administration/settings?type=autoClose">Auto Close</el-menu-item>
                <el-menu-item index="/admin/administration/settings?type=partClosure">Part Closure</el-menu-item>
            </el-submenu>
            <el-menu-item index="/admin/administration/trades-live">{{ $t('liveTrades') }}</el-menu-item>
            <el-menu-item index="/admin/administration/trades-history">{{ $t('historyTrades') }}</el-menu-item>
<!--            <el-menu-item index="/admin/administration/stats">{{ $t('stats') }}</el-menu-item>-->
<!--            <el-menu-item index="/admin/administration/reports">{{ $t('reports') }}</el-menu-item>-->
            <el-menu-item index="/admin/administration/log">{{ $t('log') }}</el-menu-item>
<!--            <el-menu-item index="/admin/administration/review">{{ $t('review') }}</el-menu-item>-->

            <div class="d-flex ms-3 me-2 align-items-center">
                <el-popover
                    placement="bottom"
                    trigger="click">
                    <AccountsSettings v-if="$route.name === 'administration-accounts'"/>
                    <ChartsSettings v-else-if="$route.name === 'administration-charts'"/>
                    <SettingsSettings v-else-if="$route.name === 'administration-settings'"/>
                    <LogSettings v-else-if="$route.name === 'administration-log'"/>
                    <div v-else>{{ $t('noSettings') }}</div>
                    <el-button slot="reference" size="small" icon="el-icon-setting" style="height: 32px; padding: 0px 9px;"/>
                </el-popover>
            </div>
            <div class="d-flex ms-2 me-2 align-items-center">
                <!-- Fullscreen toggle -->
                <el-button class="ms-auto" size="small" icon="el-icon-full-screen" @click="toggleFullscreenMode" style="height: 32px; padding: 0 9px;"/>
            </div>
            <div class="d-flex ms-auto me-3 align-items-center">
                <span style="color: #303133">{{ selectedAccount }}</span>
            </div>
        </el-menu>
        <router-view/>
        <TradeEventNotification v-model="tradeEventsNotifications"/>
        <ScrollButtons/>
    </div>
</template>

<script>
import axios from '@/plugins/axios'
import db from '@/plugins/dexie'
import TradeEventNotification from '@/components/TradeEventNotification'
import AccountsSettings from '@/views/admin/administration/accounts/AccountsSettings'
import ChartsSettings from '@/views/admin/administration/charts/ChartsSettings'
import SettingsSettings from '@/views/admin/administration/settings/SettingsSettings'
import LogSettings from '@/views/admin/administration/log/LogSettings'
import ScrollButtons from '@/components/ScrollButtons'
import AdminSideBarToggle from '@/components/AdminSideBarToggle'
const adminTradeEventsLastIdName = 'admin-trade-events-last-id'
const adminTradeEventsUpdateInterval = 5000

export default {
    name: "Administration",
    i18n: {
        messages: {
            en: {
                accounts: 'Accounts',
                charts: 'Charts',
                settings: 'Settings',
                liveTrades: 'Live',
                historyTrades: 'History',
                stats: 'Stats',
                reports: 'Reports',
                log: 'Log',
                review: 'Review',
                noSettings: 'No settings',
            },
            ru: {
                accounts: 'Счета',
                charts: 'Графики',
                settings: 'Настройки',
                liveTrades: 'Открытые',
                historyTrades: 'Закрытые',
                stats: 'Статистика',
                reports: 'Отчеты',
                log: 'Лог',
                review: 'Обзор',
                noSettings: 'Нет настроек',
            }
        }
    },
    components: {
        TradeEventNotification,
        AccountsSettings,
        ChartsSettings,
        SettingsSettings,
        LogSettings,
        ScrollButtons,
        AdminSideBarToggle,
    },
    data: function () {
        return {
            tradeEventsUpdateTimer: null,
            tradeEventsLastId: 0,
            tradeEventsLastShowedId: 0,
            tradeEventsUpdateInProcess: false,
            tradeEventsNotifications: []
        }
    },
    computed: {
        isMobile: function () {
            return this.$store.getters.DEVICE
        },
        selectedAccount: function () {
            const account = this.$store.getters.ADMIN_SELECTED_ACCOUNT
            return account ? `${account.email} (${account.name})` : ''
        },
        notificationsState: function () {
            return this.$store.getters['admin/logSettings/notificationsState']
        },
        notificationsSelectedCommands: function () {
            return this.$store.getters['admin/logSettings/notificationsSelectedCommands']
        }
    },
    methods: {
        toggleFullscreenMode: function () {
            if (!document.fullscreenElement) {
                document.documentElement.requestFullscreen()
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen()
                }
            }
        },
        fetchTradeEvents: function () {
            if(this.tradeEventsUpdateInProcess) {
                return
            }

            this.tradeEventsUpdateInProcess = true

            // Load trade events
            axios
                .get('admin/trade-events', {
                    params: {
                        last_id: this.tradeEventsLastId
                    }
                })
                .then(response => {
                    return new Promise((resolve, reject) => {
                        // Add notifications
                        response.data.forEach(tradeEvent => {
                            if(tradeEvent.id < this.tradeEventsLastShowedId) {
                                return
                            }
                            // Filter before add
                            if(!this.notificationsState) {
                                return
                            }

                            // Detect row type
                            let rowType = null
                            if(tradeEvent.command !== 6 && tradeEvent.type >= 2) {
                                rowType = 'open_pending'
                            }
                            if(tradeEvent.command === 6 && tradeEvent.type >= 2) {
                                rowType = 'close_pending'
                            }
                            if(tradeEvent.command !== 6 && tradeEvent.type <= 1) {
                                rowType = 'open_market'
                            }
                            if(tradeEvent.command === 6 && tradeEvent.type <= 1) {
                                rowType = 'close_market'
                            }

                            if(!this.notificationsSelectedCommands.includes(rowType)) {
                                return
                            }

                            this.tradeEventsNotifications.push(tradeEvent)

                            this.tradeEventsLastShowedId = tradeEvent.id
                        })

                        db.keeper_log.bulkPut(response.data)
                            .then(res => {
                                resolve(res)
                            })
                            .catch(err => {
                                if(err.name === 'InvalidStateError') {
                                    db.delete().then(()=> db.open())
                                }
                                reject(err)
                            })
                    })
                })
                .then(lastId => {
                    // If no data was put then last id will be undefined
                    if(lastId) {
                        this.tradeEventsLastId = lastId
                        localStorage.setItem(adminTradeEventsLastIdName, String(lastId))
                    }
                })
                .catch(e => {
                    console.error('Trade events update error: ', e)
                })
                .finally(() => {
                    this.tradeEventsUpdateInProcess = false
                })
        },
    },
    mounted() {
        if(this.$route.path === '/admin/administration') {
            this.$router.push('/admin/administration/accounts')
        }

        // Load logSettings (notifications)
        this.$store.dispatch('admin/logSettings/loadState')

        // Notifications (Keeper log)
        // Get last id
        this.tradeEventsLastId = localStorage.getItem(adminTradeEventsLastIdName) ?? 100000
        this.fetchTradeEvents()
        this.tradeEventsUpdateTimer = setInterval(this.fetchTradeEvents, adminTradeEventsUpdateInterval)
    },
    beforeRouteUpdate(to, from, next) {
        if(to.path === '/admin/administration') {
            next('/admin/administration/accounts')
        } else {
            next()
        }
    },
    beforeDestroy() {
        clearInterval(this.tradeEventsUpdateTimer)
    }
}
</script>

<style scoped>
.wrapper {
    margin-top: 167px;
}
 .administration-menu {
     z-index: 100;
     position: fixed;
     top: 105px;
     left: 0;
     right: 0;
 }
</style>
