<template>
    <div class="top-bottom-buttons">
        <div class="back-to-button to-up-btn" @click="handleUpButtonClick">
            <i class="icon el-icon-top"></i>
        </div>
        <div class="back-to-button to-down-btn" @click="handleDownButtonClick">
            <i class="icon el-icon-bottom"></i>
        </div>
    </div>
</template>

<script>
const DELAY = 200
export default {
    name: 'ScrollButtons',
    data: function () {
        return {
            clicksUp: 0,
            timerUp: null,
            clicksDown: 0,
            timerDown: null,
        }
    },
    methods: {
        handleUpButtonClick: function () {
            this.clicksUp++
            if (this.clicksUp === 1) {
                this.timerUp = setTimeout(() => {
                    const currentPos = window.document.scrollingElement.scrollTop
                    const scrollFor = window.document.scrollingElement.clientHeight
                    window.scrollTo({
                        top: currentPos - scrollFor
                    })
                    this.clicksUp = 0
                }, DELAY)
            } else {
                clearTimeout(this.timerUp)
                window.scrollTo({
                    top: 0
                })
                this.clicksUp = 0
            }
        },
        handleDownButtonClick: function () {
            this.clicksDown++
            if (this.clicksDown === 1) {
                this.timerDown = setTimeout(() => {
                    const currentPos = window.document.scrollingElement.scrollTop
                    const scrollFor = window.document.scrollingElement.clientHeight
                    window.scrollTo({
                        top: currentPos + scrollFor
                    })
                    this.clicksDown = 0
                }, DELAY)
            } else {
                clearTimeout(this.timerDown)
                window.scrollTo({
                    top: window.document.scrollingElement.scrollHeight
                })
                this.clicksDown = 0
            }
        }
    },
}
</script>

<style scoped>
.icon {
    font-size: 22px;
    font-weight: 600;
}

.back-to-button {
    padding: 0.5em;
    border-radius: 5px;
    color: #32A0FF;
    font-size: 17px;
    opacity: 0.8;
}

.back-to-button:hover {
    background: #f0f0f0;
    cursor: pointer;
    opacity: 1;
}

.top-bottom-buttons {
    position: fixed;
    bottom: 10px;
    right: 10px;
}
</style>
