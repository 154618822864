<template>
    <div v-if="!!this.latestNotification" class="notification-wrapper no-select">
        <div class="toast show" :style="{ backgroundColor: backgroundColor(latestNotification.command), display: 'block', }">
            <div class="toast__icon" v-html="icon(latestNotification.command, latestNotification.type)"></div>
            <div class="toast__content">
                <p class="toast__date" v-html="dateTimeToString(latestNotification['open_time'])"></p>
                <p class="toast__bold">{{ latestNotification.name.toUpperCase() }} {{ latestNotification.symbol }} <span v-if="latestNotification.command === 6">CLOSE</span>
                    <span v-if="latestNotification.command === 0">OPEN</span>
                    <span v-if="latestNotification.command === 1">OPEN</span>
                    <span v-if="latestNotification.command === 2">OPEN</span>
                    <span v-if="latestNotification.command === 3">OPEN</span>
                    <span v-if="latestNotification.command === 4">OPEN</span>
                    <span v-if="latestNotification.command === 5">OPEN</span> {{ tradeTypeToName(latestNotification.type) }}</p>
                <p>{{ latestNotification.ticket }}, lots: {{ latestNotification.lots }}, <span v-if="latestNotification.command === 6 && latestNotification.type <= 1">profit: {{ latestNotification.profit}},</span> {{ tradeMagicToName(latestNotification.magic) }}</p>
                <p>Lots: buy {{ latestNotification['buy_lots'] }}, sell {{ latestNotification['sell_lots'] }} <span class="float-end"># {{ notificationsCount }}</span></p>
            </div>
            <div class="toast__close" >
                <span class="toast__close__all" @click="dismissNotification(true)">Close all</span>
                <span @click="dismissNotification(false)">&#10005;</span>
            </div>
        </div>
    </div>
</template>

<script>
import { tradeTypeToName, tradeMagicToName, dateTimeToString } from "@/common/helpers"
const notificationAudioFilePath = require('@/assets/audio/appointed.mp3')

export default {
    name: "TradeEventNotification",
    props: {
        value: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        latestNotification: function () {
            return this.value[this.value.length - 1]
        },
        notificationsCount: function () {
            return this.value.length
        }
    },
    watch: {
        notificationsCount: function (newValue, oldValue) {
            if(newValue > oldValue) {
                this.playNotificationAudio()
            }
        },
    },
    methods: {
        tradeTypeToName,
        tradeMagicToName,
        dateTimeToString,
        backgroundColor: function (command) {
            if(command <= 5) {
                return 'rgb(222, 222, 222)'
            }

            if(command === 6) {
                return 'rgb(177, 177, 177)'
            }

            return 'rgb(255, 255, 255)'
        },
        icon: function (command, type) {
            let icon = ''
            let iconStyle = ''
            if (command <= 5) {
                //buy
                if (type === 0) {
                    iconStyle = 'fill: #191970; transform: rotate(calc(0deg))'
                }
                //sell
                if (type === 1) {
                    iconStyle = 'fill: #ff0000; transform: rotate(calc(180deg))'
                }
                //buy limit
                if (type === 2) {
                    iconStyle = 'fill: #36c7f7; transform: rotate(calc(0deg))'
                }
                //sell limit
                if (type === 3) {
                    iconStyle = 'fill: #FF1493; transform: rotate(calc(180deg))'
                }
                //buy stop
                if (type === 4) {
                    iconStyle = 'fill: #36c7f7; transform: rotate(calc(0deg));'
                }
                //sell stop
                if (type === 5) {
                    iconStyle = 'fill: #FF1493; transform: rotate(calc(180deg));'
                }

                icon = `<svg style="width:45px; ${iconStyle}" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 60 60" xml:space="preserve"><polygon points="30,0 8.9,26.9 21.5,26.9 21.5,60 38.5,60 38.5,26.9 51.1,26.9"/></svg>`
            }

            if (command === 6) {
                //buy
                if (type === 0) {
                    iconStyle = 'fill: #191970;'
                }
                //sell
                if (type === 1) {
                    iconStyle = 'fill: #ff0000;'
                }
                //buy limit
                if (type === 2) {
                    iconStyle = 'fill: #36c7f7;'
                }
                //sell limit
                if (type === 3) {
                    iconStyle = 'fill: #FF1493;'
                }
                //buy stop
                if (type === 4) {
                    iconStyle = 'fill: #36c7f7;'
                }
                //sell stop
                if (type === 5) {
                    iconStyle = 'fill: #FF1493;'
                }
                icon = `<svg style="width:45px; ${iconStyle}" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 60 60" xml:space="preserve"><g><path d="M15.6,9.9h19.3c3.8,0,6.9,1.1,9.2,3.2s3.5,5.2,3.5,9.1c0,3.4-1,6.3-3.1,8.8c-2.1,2.5-5.3,3.7-9.6,3.7H21.5v18.2h-5.8V9.9z M38.3,15.8c-1.3-0.6-3-0.9-5.3-0.9H21.5v14.9H33c2.6,0,4.7-0.6,6.3-1.7s2.4-3.1,2.4-5.9C41.7,19.1,40.6,16.9,38.3,15.8z"/></g></svg>`
            }

            return icon
        },
        dismissNotification: function (all = false) {
            if(all) {
                this.$emit('input', [])
            } else {
                this.$emit('input', this.value.slice(0, -1))
            }

        },
        playNotificationAudio: function () {
            const audio = new Audio(notificationAudioFilePath)
            audio.play().catch(() => {
                console.log('Error playing audio. The audio cannot be played until the user clicks anywhere on the page.')
            })
        },
    },
}
</script>

<style scoped lang="scss">
.notification-wrapper {
    z-index: 1000;
    position: fixed;
    top: 5px;
    right: 0;
}

.toast {
    margin: 0 10px;
    border: 1px solid #EBEEF5;
    text-align: left;
    padding: 10px 0;
    max-width: 400px;
    position: relative;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    opacity: 1;
    font-size: 14px;
}
.toast:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    border-top-left-radius:4px;
    border-bottom-left-radius: 4px;
}
.toast__icon{
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
}
.toast__date {
    margin-top: 0;
    margin-bottom: 0;
    color: #484848;
}
.toast__bold {
    font-weight: bold;
}

.toast__content{
    padding-left:60px;
    padding-right: 30px;
}
.toast__content p {
    margin: 0;
}
.toast__close {
    position: absolute;
    right: 10px;
    top: 8px;
    cursor: pointer;
    height: 14px;
    color: grey;
}

.toast__close__all {
    margin-right: 15px;
    display: none;
}

@media (max-width: 576px) {
    .toast__close__all {
        display: inline-block;
    }
}

.toast__close:hover {
    .toast__close__all {
        animation-name: slideInRight-enter;
        animation-duration: 0.4s;
        display: inline-block;
    }
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
</style>
