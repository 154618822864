<template>
    <div class="container">
        <div class="row">
            <div class="col-12 col-sm-6 mb-1 mb-sm-0">
                <div class="mb-2 d-flex align-items-baseline">
                    <span style="width: 100px">{{ $t('columns') }}:</span>
                    <el-select
                        multiple
                        collapse-tags
                        size="small"
                        v-model="selectedColumnsLocal"
                        @visible-change="handleSelectedColumnsVisibleChange"
                        :placeholder="$t('columns')">
                        <el-option
                            v-for="column in columnsOptions"
                            :key="column.value"
                            :label="column.label"
                            :value="column.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="mb-2 d-flex align-items-baseline">
                    <span style="width: 100px">{{ $t('accounts') }}:</span>
                    <el-select
                        multiple
                        collapse-tags
                        size="small"
                        :value="selectedAccounts"
                        @change="handleSelectedAccountsChange"
                        :placeholder="$t('columns')">
                        <el-option
                            v-for="account in accounts"
                            :key="account.account_id"
                            :label="account.name"
                            :value="account.account_id">
                            {{ `${account.email} (${account.name}) ${account.number}` }}
                        </el-option>
                    </el-select>
                </div>
                <div class="mb-2 d-flex align-items-baseline">
                    <span style="width: 100px">{{ $t('plDays') }}:</span>
                    <el-select
                        size="small"
                        :value="profitLossOffset"
                        @change="handleProfitLossOffsetChange"
                        placeholder="">
                        <el-option
                            v-for="column in profitLossOffsetOptions"
                            :key="column.value"
                            :label="column.label"
                            :value="column.value"/>
                    </el-select>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="mb-2 d-flex align-items-baseline">
                    <span style="width: 150px">{{ $t('marginLimit') }}:</span>
                    <el-input-number
                        size="small"
                        :value="marginLimit"
                        controls-position="right"
                        @change="handleMarginLimitChange"
                        :min="1"
                        :step="10"
                        :max="Number.MAX_SAFE_INTEGER"/>
                </div>
                <div class="mb-2 d-flex align-items-baseline">
                    <span style="width: 150px">{{ $t('maxProfitBalance') }}:</span>
                    <el-input-number
                        size="small"
                        :value="maxProfitBalanceRatio"
                        controls-position="right"
                        @change="handleMaxProfitBalanceRatioChange"
                        :min="0"
                        :step="0.1"
                        :precision="2"
                        :max="Number.MAX_SAFE_INTEGER"/>
                </div>
                <div class="mb-2 d-flex align-items-baseline">
                    <span style="width: 150px">{{ $t('maxLots') }}:</span>
                    <el-input-number
                        size="small"
                        :value="maxLots"
                        controls-position="right"
                        @change="handleMaxLotsChange"
                        :min="0"
                        :step="0.1"
                        :precision="2"
                        :max="Number.MAX_SAFE_INTEGER"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import {mapGetters} from 'vuex'

export default {
    name: "AccountsSettings",
    i18n: {
        messages: {
            en: {
                columns: 'Columns',
                accounts: 'Accounts',
                plDays: 'P/L Days',
                marginLimit: 'Margin limit',
                maxProfitBalance: 'Max profit/balance',
                maxLots: 'Max lots',

                day: 'Day',
                week: 'Week',
                month: 'Month',
            },
            ru: {
                columns: 'Столбцы',
                accounts: 'Счета',
                plDays: 'P/L дни',
                marginLimit: 'Мин маржа',
                maxProfitBalance: 'Макс приб./бал.',
                maxLots: 'Макс лотов',

                day: 'День',
                week: 'Неделя',
                month: 'Месяц',
            }
        }
    },
    data: function () {
        return {
            selectedColumnsLocal: [],
            profitLossOffsetOptions: [
                {
                    label: this.$t('day'),
                    value: 0,
                },
                {
                    label: this.$t('week'),
                    value: 1,
                },
                {
                    label: this.$t('month'),
                    value: 2,
                },
            ],
        }
    },
    computed: {
        // ...mapGetters([
        //     'admin/accountsSettings/marginLimit',
        //     'admin/accountsSettings/maxProfitBalanceRatio',
        //     'admin/accountsSettings/maxLots',
        // ]),
        accounts: function () {
            return this.$store.getters['admin/accounts']
        },
        selectedAccounts: function () {
            return this.$store.getters['admin/accountsSettings/selectedAccounts']
        },
        columnsOptions: function () {
            return this.$store.getters['admin/accountsSettings/columnsOptions']
        },
        selectedColumns: function () {
            return this.$store.getters['admin/accountsSettings/selectedColumns']
        },
        profitLossOffset: function () {
            return this.$store.getters['admin/accountsSettings/profitLossOffset']
        },
        marginLimit: function () {
            return this.$store.getters['admin/accountsSettings/marginLimit']
        },
        maxProfitBalanceRatio: function () {
            return this.$store.getters['admin/accountsSettings/maxProfitBalanceRatio']
        },
        maxLots: function () {
            return this.$store.getters['admin/accountsSettings/maxLots']
        },
    },
    watch: {
        selectedColumns: function (newValue) {
            this.selectedColumnsLocal = newValue
        },
    },
    methods: {
        handleSelectedColumnsVisibleChange: function (visible) {
            if(!visible) {
                this.$store.commit('admin/accountsSettings/setSelectedColumns', this.selectedColumnsLocal)
            }
        },
        handleSelectedAccountsChange: function (value) {
            this.$store.commit('admin/accountsSettings/setSelectedAccounts', value)
        },
        handleProfitLossOffsetChange: function (value) {
            this.$store.commit('admin/accountsSettings/setProfitLossOffset', value)
        },
        handleMarginLimitChange: function (value) {
            this.$store.commit('admin/accountsSettings/setMarginLimit', value)
        },
        handleMaxProfitBalanceRatioChange: function (value) {
            this.$store.commit('admin/accountsSettings/setMaxProfitBalanceRatio', value)
        },
        handleMaxLotsChange: function (value) {
            this.$store.commit('admin/accountsSettings/setMaxLots', value)
        }
    },
    mounted() {
        this.selectedColumnsLocal = this.selectedColumns
        this.$store.dispatch('admin/accountsGetRequest')
        .then(() => {
            if(!this.selectedAccounts.length) {
                this.handleSelectedAccountsChange(this.accounts.map(account => account.account_id))
            }
        })
    }
}
</script>

<style scoped>

</style>
