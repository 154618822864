<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="mb-3 d-flex align-items-center">
                    <span style="width: 260px">{{ $t('Show trends and signals?') }}</span>
                    <el-switch class="mx-2" v-model="isTrendsAndSignalsVisible" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                </div>
                <div class="d-flex align-items-center">
                    <span style="width: 260px">{{ $t('Show accumulated profit?') }}</span>
                    <el-switch class="mx-2" v-model="isAccumulatedProfitVisible" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChartsSettings',
    i18n: {
        messages: {
            ru: {
                'Show trends and signals?': 'Показывать тренды и сигналы?',
                'Show accumulated profit?': 'Показывать накопленную прибыль?',
            }
        }
    },
    computed: {
        isTrendsAndSignalsVisible: {
            get: function () {
                return this.$store.getters['admin/chartsSettings/isTrendsAndSignalsVisible']
            },
            set: function (value) {
                this.$store.commit('admin/chartsSettings/setIsTrendsAndSignalsVisible', value)
                this.$store.dispatch('admin/chartsSettings/saveState')
            }
        },
        isAccumulatedProfitVisible: {
            get: function () {
                return this.$store.getters['admin/chartsSettings/isAccumulatedProfitVisible']
            },
            set: function (value) {
                this.$store.commit('admin/chartsSettings/setIsAccumulatedProfitVisible', value)
                this.$store.dispatch('admin/chartsSettings/saveState')
            }
        }
    },
    mounted() {
        this.$store.dispatch('admin/chartsSettings/loadState')
    }
}
</script>

<style scoped>

</style>
