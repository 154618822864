<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h4 class="mb-3">{{ $t('notifications') }}</h4>
                <div class="mb-3 d-flex align-items-center">
                    <span style="width: 100px">{{ $t('show') }}:</span>
                    <el-switch class="mx-2" v-model="notificationsState" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                </div>
                <div class="mb-3 d-flex align-items-center">
                    <span style="width: 100px">{{ $t('commands') }}:</span>
                    <el-select
                        class="mx-2"
                        size="small"
                        multiple
                        collapse-tags
                        v-model="notificationsSelectedCommands"
                        :placeholder="$t('commands')">
                        <el-option
                            v-for="command in notificationsCommandsOptions"
                            :key="`command-filter-${command.value}`"
                            :label="command.label"
                            :value="command.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    i18n: {
        messages: {
            en: {
                notifications: 'Notifications',
                show: 'Show',
                commands: 'Commands',
            },
            ru: {
                notifications: 'Уведомления',
                show: 'Показывать',
                commands: 'Команды',
            }
        }
    },
    name: 'LogSettings',
    computed: {
        notificationsCommandsOptions: function () {
            return this.$store.getters['admin/logSettings/commandsOptions']
        },
        notificationsSelectedCommands: {
            get: function () {
                return this.$store.getters['admin/logSettings/notificationsSelectedCommands']
            },
            set: function (value) {
                this.$store.commit('admin/logSettings/setNotificationsSelectedCommands', value)
                this.$store.dispatch('admin/logSettings/saveState')
            }
        },
        notificationsState: {
            get: function () {
                return this.$store.getters['admin/logSettings/notificationsState']
            },
            set: function (value) {
                this.$store.commit('admin/logSettings/setNotificationsState', value)
                this.$store.dispatch('admin/logSettings/saveState')
            }
        }
    },
    mounted() {
        this.$store.dispatch('admin/logSettings/loadState')
    },
}
</script>

<style scoped>

</style>
